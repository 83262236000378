import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Construct = () => (
  <Layout>
    <SEO title="納入実績" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <div className="pt-32 font-mincho">
          <div className="border-l-4 border-x-primary bg-secondery py-4 pl-4 text-sm">
            <h1 className="text-3xl text-primary">高島屋 大阪店</h1>
            <span className="mt-4 mb-1 block">製品 : モールディング</span>
            <span className="my1 block">設計 : アートマテリアル</span>
            <span className="my-1 block">施工 : アートマテリアル</span>
            <span className="my-1 block">撮影 : ナカサアンドパートナーズ</span>
          </div>
        </div>
        <div className="mt-4 grid w-full grid-cols-1 gap-2 md:grid-cols-2 md:gap-2 ">
          <StaticImage
            src="../../images/works/takashimaya/1.jpg"
            alt="高島屋大阪店1"
            className="w-full"
            quality={100}
          />
          <StaticImage
            src="../../images/works/takashimaya/2.jpg"
            alt="高島屋大阪店SA2"
            className="w-full"
            quality={100}
          />
          <StaticImage
            src="../../images/works/takashimaya/3.jpg"
            alt="高島屋大阪店SA3"
            className="w-full"
            quality={100}
          />
        </div>
      </section>
    </div>
  </Layout>
)

export default Construct
